export const presets = [
    {
        "textureVisibility": 0,
        "texture": "None",
        "speed": 0.24822184589331076,
        "colour": 0.5,
        "frequency": 0,
        "density": 0.4,
        "strength": .8,
        "amplification": 0,
        "red": 0,
        "green": 0,
        "blue": 0,
        "brightness": 0.588954940983889,
        "contrast": 1.7954682519169467,
        "oscilation": 0.2,
        "wireframe": false,
        "reflectivity": 0.43,
        "gradStart": {
            "r": 0,
            "g": 0,
            "b": 0
        },
        "gradEnd": {
            "r": 0,
            "g": 0,
            "b": 0
        }
    },

    {
        "textureVisibility": 0,
        "texture": "None",
        "speed": 0.05313293818797629,
        "colour": 0.5,
        "frequency": 0,
        "density": 0.8564775613886537,
        "strength": 0.9040643522438611,
        "amplification": 0,
        "red": 0,
        "green": 0.1,
        "blue": 0,
        "brightness": 0.46764883260101664,
        "contrast": 0.780908072714741,
        "oscilation": 0.08167485138278625,
        "wireframe": false,
        "reflectivity": 0.3,
        "gradStart": {
            "r": 210.62653882575762,
            "g": 140.44921875,
            "b": 239.7
        },
        "gradEnd": {
            "r": 0,
            "g": 214,
            "b": 214
        }
    },

    {
        "textureVisibility": 0,
        "texture": "None",
        "speed": 0.015198983911939034,
        "colour": 0.5,
        "frequency": 10,
        "density": 1,
        "strength": 3,
        "amplification": 0.7375105842506351,
        "red": 0.37,
        "green": 0.26,
        "blue": 0,
        "brightness": 1,
        "contrast": 0.33979495132247783,
        "oscilation": 0.13681399155681917,
        "wireframe": false,
        "reflectivity": 0,
        "gradStart": {
            "r": 255,
            "g": 0,
            "b": 0
        },
        "gradEnd": {
            "r": 69.3874124999999,
            "g": 40.196765625,
            "b": 186.15
        }
    },

    {
        "textureVisibility": 0,
        "texture": "None",
        "speed": 0.05313293818797629,
        "colour": 0.5,
        "frequency": 1.7129551227773074,
        "density": 2.373835732430144,
        "strength": 1.684419983065199,
        "amplification": 3.447078746824725,
        "red": 0.5,
        "green": 0.26,
        "blue": 0,
        "brightness": 0.75,
        "contrast": 0.1633497027655725,
        "oscilation": 0.22503661583527182,
        "wireframe": false,
        "reflectivity": 0.06,
        "gradStart": {
            "r": 255,
            "g": 0,
            "b": 0
        },
        "gradEnd": {
            "r": 73.6681890120968,
            "g": 8.743312499999998,
            "b": 132.6
        }
    },

    {
        "textureVisibility": 0,
        "texture": "None",
        "speed": 0.1,
        "colour": 0.5,
        "frequency": 3.5,
        "density": 2.0,
        "strength": 0.3,
        "amplification": 6.0,
        "red": 0.5,
        "green": 0.2,
        "blue": 0,
        "brightness": .5,
        "contrast": .5,
        "oscilation": 1.0,
        "wireframe": false,
        "reflectivity": .1,
        "gradStart": {r: 255, g: 255, b: 255},
        "gradEnd": {r: 255, g: 255, b: 255}
    },

    {
        "textureVisibility": 0,
        "texture": "None",
        "speed": 0.015198983911939034,
        "colour": 0,
        "frequency": 10,
        "density": 5,
        "strength": 3,
        "amplification": 1.1710414902624895,
        "red": 0.5,
        "green": 0.22,
        "blue": 0.33,
        "brightness": 1,
        "contrast": 0.33979495132247783,
        "oscilation": 0.11,
        "wireframe": true,
        "reflectivity": 0,
        "gradStart": {
            "r": 255,
            "g": 0,
            "b": 0
        },
        "gradEnd": {
            "r": 69.3874124999999,
            "g": 40.196765625,
            "b": 186.15
        }
    },

    {
        "textureVisibility": 0,
        "texture": "None",
        "speed": 0.05855207451312447,
        "colour": 0.4,
        "frequency": 10,
        "density": 4.866638441998306,
        "strength": 0.9365791701947503,
        "amplification": 3.230313293818798,
        "red": 0,
        "green": 0.22,
        "blue": 0.5,
        "brightness": 1,
        "contrast": 0.7367967605755148,
        "oscilation": 0.32428706814853103,
        "wireframe": true,
        "reflectivity": 0.43,
        "gradStart": {
            "r": 249.9,
            "g": 161.58192771084342,
            "b": 0
        },
        "gradEnd": {
            "r": 137.4244912790698,
            "g": 255,
            "b": 126.46406250000001
        }
    },

    {
        "textureVisibility": 0,
        "texture": "None",
        "speed": 0.05313293818797629,
        "colour": 0.5,
        "frequency": 0,
        "density": 5,
        "strength": 1.8795088907705333,
        "amplification": 0,
        "red": 0.42,
        "green": 0.5,
        "blue": 0.5,
        "brightness": 0.4345653484965969,
        "contrast": 0.780908072714741,
        "oscilation": 0.07064702334797966,
        "wireframe": false,
        "reflectivity": 0.11,
        "gradStart": {
            "r": 117.10047336647732,
            "g": 77.450671875,
            "b": 211.64999999999998
        },
        "gradEnd": {
            "r": 196.39512753378378,
            "g": 108.75909375,
            "b": 219.29999999999998
        }
    },


    {
        "textureVisibility": 0,
        "texture": "None",
        "speed": 0.35660457239627436,
        "colour": 0.4,
        "frequency": 10,
        "density": 5,
        "strength": 1.2292125317527518,
        "amplification": 1.9297205757832345,
        "red": 0.22,
        "green": 0.17,
        "blue": 0.1,
        "brightness": 1,
        "contrast": 2,
        "oscilation": 0.20298095976565866,
        "wireframe": false,
        "reflectivity": 0.54,
        "gradStart": {
            "r": 255,
            "g": 14.000000000000007,
            "b": 14.000000000000007
        },
        "gradEnd": {
            "r": 132,
            "g": 48.20000000000003,
            "b": 48.00000000000001
        }
    },

    {
        "textureVisibility": 0,
        "texture": "None",
        "speed": 0.35660457239627436,
        "colour": 0.4,
        "frequency": 10,
        "density": 5,
        "strength": 1.2292125317527518,
        "amplification": 1.9297205757832345,
        "red": 0.22,
        "green": 0.17,
        "blue": 0.1,
        "brightness": 1,
        "contrast": 2,
        "oscilation": 0.20298095976565866,
        "wireframe": false,
        "reflectivity": 0.54,
        "gradStart": {
            "r": 255,
            "g": 14.000000000000007,
            "b": 14.000000000000007
        },
        "gradEnd": {
            "r": 132,
            "g": 48.20000000000003,
            "b": 48.00000000000001
        }
    },

    {
        "textureVisibility": 0,
        "texture": "None",
        "speed": 0,
        "colour": 0.5,
        "frequency": 0,
        "density": 10,
        "strength": 3,
        "amplification": 0,
        "red": 0,
        "green": 0.03,
        "blue": 0,
        "brightness": 0.5227879727750495,
        "contrast": 0.20746101490479882,
        "oscilation": 0.08167485138278625,
        "wireframe": false,
        "reflectivity": 0.25,
        "gradStart": {
            "r": 167.35825312499998,
            "g": 165.03042187499997,
            "b": 170.85
        },
        "gradEnd": {
            "r": 55.58760937499999,
            "g": 201.45000000000002,
            "b": 201.45000000000002
        }
    }
]