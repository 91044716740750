import * as THREE from 'three';
import vertexShader from './shaders/vertex.glsl';
import fragmentShader from './shaders/fragment.glsl';
import dat from "dat.gui"
import gsap from "gsap"
gsap.defaults({
    duration: 4,
    ease: "power3.inOut"
});

import px from "../../evmap/px.jpg";
import nx from "../../evmap/nx.jpg";
import py from "../../evmap/py.jpg";
import ny from "../../evmap/ny.jpg";
import pz from "../../evmap/pz.jpg";
import nz from "../../evmap/nz.jpg";

import rockTexture from "../../textures/Rock_047_BaseColor.jpg";
import metalTexture from "../../textures/Metal_006_roughness.jpg";
import hedgeTexture from "../../textures/Hedge_001_Height.png";
import malachiteTexture from "../../textures/Malachite_001_basecolor.jpg";
import hammeredMetalTexture from "../../textures/Metal_Hammered_003_basecolor.jpg";
import rustedMetalTexture from "../../textures/Metal_Rusted_010_basecolor.jpg";
import scratchedMetalTexture from "../../textures/Metal_scratched_009_roughness.jpg";
import plasticTexture from "../../textures/Plastic_003_roughness.jpg";
import rockMossTexture from "../../textures/Rock_Moss_001_basecolor.jpg";

const textureMaps = {
    "None": "",
    "Rock": new THREE.TextureLoader().load(rockTexture),
    "Metal": new THREE.TextureLoader().load(metalTexture),
    "Hedge": new THREE.TextureLoader().load(hedgeTexture),
    "Malachite": new THREE.TextureLoader().load(malachiteTexture),
    "Hammered Metal": new THREE.TextureLoader().load(hammeredMetalTexture),
    "Rusted Metal": new THREE.TextureLoader().load(rustedMetalTexture),
    "Scratched Metal": new THREE.TextureLoader().load(scratchedMetalTexture),
    "Plastic": new THREE.TextureLoader().load(plasticTexture),
    "Rock Moss": new THREE.TextureLoader().load(rockMossTexture),
}

import {presets} from "./presets"

const initialPreset = 0

let gui, colours, textures, blobOptions, gradientOptions
window.blobSettings

export default class Blob extends THREE.Object3D {
  constructor() {
    super();

    gui = new dat.GUI();

    this.presets = presets
    window.blobSettings = presets[initialPreset]

    this.selectedPreset = initialPreset;
    const presetOptions = this.presets.map((preset, index) => `Preset ${index}`);

    gui.add(this, 'selectedPreset', presetOptions).name('Select Preset').onChange((value) => {
      const presetIndex = presetOptions.indexOf(value);
      if (presetIndex !== -1) {
        this.applyPreset(this.presets[presetIndex]);
        this.selectedPreset = presetIndex;
      }
    });

    gui.add({copyToClipboard: function() {
        const settingsStr = JSON.stringify(blobSettings, null, 2);
        navigator.clipboard.writeText(settingsStr).then(() => {
            alert('Settings copied to clipboard');
        }).catch(err => {
            console.error('Could not copy settings to clipboard', err);
        });
    }}, 'copyToClipboard').name('Copy Settings');
    

    blobOptions = gui.addFolder('World options');

    colours = blobOptions.addFolder('World colours');
    colours.add(blobSettings, 'colour', 0, 0.5);
    colours.add(blobSettings, 'red', 0, .5, 0.01);
    colours.add(blobSettings, 'green', 0, .5, .01);
    colours.add(blobSettings, 'blue', 0, .5, 0.01);
    colours.add(blobSettings, 'oscilation', 0, 1.0);
    colours.add(blobSettings, 'contrast', 0, 2.0);
    colours.add(blobSettings, 'brightness', 0, 1.0);

    colours.__controllers.forEach((controller) => {
        controller.onChange(() => {
          this.material.uniforms.uHue.value = blobSettings.colour;
          this.material.uniforms.uRed.value = blobSettings.red;
          this.material.uniforms.uGreen.value = blobSettings.green;
          this.material.uniforms.uBlue.value = blobSettings.blue;
          this.material.uniforms.uOscilation.value = blobSettings.oscilation;
            this.material.uniforms.uContrast.value = blobSettings.contrast;
            this.material.uniforms.uBrightness.value = blobSettings.brightness;
            gsap.to(".main", { background: `linear-gradient(90deg, rgb(${blobSettings.gradStart.r},${blobSettings.gradStart.g},${blobSettings.gradStart.b}) 0%, rgb(${blobSettings.red * 500},${blobSettings.green * 500},${blobSettings.blue * 500}) 57%, rgb(${blobSettings.gradEnd.r},${blobSettings.gradEnd.g},${blobSettings.gradEnd.b}) 100%)`, duration: 0 })
        });
    });

    
    textures = blobOptions.addFolder('World texture');

    blobSettings.textureIndex = 0;
    const textureNames = Object.keys(textureMaps);
    textures.add(blobSettings, 'textureIndex', textureNames).name('Texture')
    
    textures.add(blobSettings, 'textureVisibility', 0, 1.0, 0.01)

    textures.__controllers.forEach((controller) => {
        controller.onChange((value) => {
            if(typeof value === "number") {
                this.material.uniforms.uTextureVisibility.value = blobSettings.textureVisibility;
            }else{
                if (textureMaps[blobSettings.textureIndex] !== this.material.uniforms.uTexture.value) {
                    this.material.uniforms.uPrevTexture.value = this.material.uniforms.uTexture.value;
                    this.material.uniforms.uTexture.value = textureMaps[blobSettings.textureIndex];
                    gsap.fromTo(this.material.uniforms.uTextureLerpFactor, { value: 0.0 }, { value: 1.0, duration: 2 });
                  }
            }
        });
      });
      


    gradientOptions = blobOptions.addFolder('Background gradient');
    gradientOptions.addColor(blobSettings, 'gradStart')
    gradientOptions.addColor(blobSettings, 'gradEnd')

    gradientOptions.__controllers.forEach((controller) => {
        controller.onChange(() => {
            this.material.uniforms.uGradientStartColor.value = new THREE.Color(blobSettings.gradStart.r / 255, blobSettings.gradStart.g / 255, blobSettings.gradStart.b / 255);
            this.material.uniforms.uGradientEndColor.value = new THREE.Color(blobSettings.gradEnd.r / 255, blobSettings.gradEnd.g / 255, blobSettings.gradEnd.b / 255);
            gsap.to(".main", { background: `linear-gradient(90deg, rgb(${blobSettings.gradStart.r},${blobSettings.gradStart.g},${blobSettings.gradStart.b}) 0%, rgb(${blobSettings.red * 500},${blobSettings.green * 500},${blobSettings.blue * 500}) 57%, rgb(${blobSettings.gradEnd.r},${blobSettings.gradEnd.g},${blobSettings.gradEnd.b}) 100%)`, duration: 0 })
        });
    });


    blobOptions.add(blobSettings, 'speed', 0, .5);
    blobOptions.add(blobSettings, 'frequency', 0, 10.0);
    blobOptions.add(blobSettings, 'density', 0, 100.0);
    blobOptions.add(blobSettings, 'strength', 0, 4.0);
    blobOptions.add(blobSettings, 'amplification', 0, 10.0);
    blobOptions.add(blobSettings, 'wireframe', true, false);
    blobOptions.add(blobSettings, 'reflectivity', 0, 1.0, .01);

    blobOptions.__controllers.forEach((controller) => {
        controller.onChange(() => {
          this.material.uniforms.uSpeed.value = blobSettings.speed;
          this.material.uniforms.uNoiseDensity.value = blobSettings.density;
          this.material.uniforms.uNoiseStrength.value = blobSettings.strength;
          this.material.uniforms.uFreq.value = blobSettings.frequency;
          this.material.uniforms.uAmp.value = blobSettings.amplification;
            this.material.uniforms.uReflectivity.value = blobSettings.reflectivity;
          this.material.wireframe = blobSettings.wireframe;
        });
    });


    const loader = new THREE.CubeTextureLoader();
    const envMap = loader.load([px,nx,py,ny,pz,nz]);
    envMap.mapping = THREE.CubeRefractionMapping;

    this.geometry = new THREE.IcosahedronGeometry(4.5, 128);
    this.material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        uTexture: { value: textureMaps[blobSettings.texture] },
        uPrevTexture: { value: null },
        uTextureLerpFactor: { value: 0.0 },
        uTextureVisibility: { value: blobSettings.textureVisibility },
        uEnvMap: { value: envMap },
        uReflectivity: { value: blobSettings.reflectivity },
        uTime: { value: 0 },
        uSpeed: { value: blobSettings.speed },
        uNoiseDensity: { value: blobSettings.density },
        uNoiseStrength: { value: blobSettings.strength },
        uFreq: { value: blobSettings.frequency },
        uAmp: { value: blobSettings.amplification },
        uHue: { value: blobSettings.color },
        uOffset: { value: 0 },
        uRed: { value: blobSettings.red },
        uGreen: { value: blobSettings.green },
        uBlue: { value: blobSettings.blue },
        uGradientStartColor: { value: new THREE.Color(blobSettings.gradStart.r / 255, blobSettings.gradStart.g / 255, blobSettings.gradStart.b / 255) },
        uGradientEndColor: { value: new THREE.Color(blobSettings.gradEnd.r / 255, blobSettings.gradEnd.g / 255, blobSettings.gradEnd.b / 255) },
        uAlpha: { value: 1.0 },
        uBrightness: {value: blobSettings.brightness},
        uContrast: {value: blobSettings.contrast},
        uOscilation: {value: blobSettings.oscilation},
      },
      defines: {
        PI: Math.PI
      },
        wireframe: blobSettings.wireframe,
      // side: THREE.DoubleSide
      transparent: true,
    });

    gsap.to(".main", { background: `linear-gradient(90deg, rgb(${blobSettings.gradStart.r},${blobSettings.gradStart.g},${blobSettings.gradStart.b}) 0%, rgb(${blobSettings.red * 500},${blobSettings.green * 500},${blobSettings.blue * 500}) 57%, rgb(${blobSettings.gradEnd.r},${blobSettings.gradEnd.g},${blobSettings.gradEnd.b}) 100%)` })

    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.add(this.mesh);
  }

  applyPreset(preset) {
        const newTexture = textureMaps[preset.textureIndex];
        blobSettings.textureIndex = preset.textureIndex;

        if (newTexture !== this.material.uniforms.uTexture.value) {
            this.material.uniforms.uPrevTexture.value = this.material.uniforms.uTexture.value;
    
            this.material.uniforms.uTexture.value = newTexture;
    
            gsap.fromTo(this.material.uniforms.uTextureLerpFactor, {value: 0.0}, {value: 1.0});
        }
    this.resetSettings(preset);
  }


  resetSettings(newSettings) {
    Object.assign(blobSettings, newSettings);

    gsap.to(this.material.uniforms.uSpeed, { value: blobSettings.speed, onComplete: () => {
        gui.__controllers.forEach(controller => controller.updateDisplay());
        blobOptions.__controllers.forEach(controller => controller.updateDisplay());
        colours.__controllers.forEach(controller => controller.updateDisplay());
        textures.__controllers.forEach(controller => controller.updateDisplay());
        gradientOptions.__controllers.forEach(controller => controller.updateDisplay());
    }})
    
    this.material.wireframe = blobSettings.wireframe;
    gsap.to(this.material.uniforms.uNoiseDensity, { value: blobSettings.density })
    gsap.to(this.material.uniforms.uNoiseStrength, { value: blobSettings.strength })
    gsap.to(this.material.uniforms.uFreq, { value: blobSettings.frequency })
    gsap.to(this.material.uniforms.uAmp, { value: blobSettings.amplification })
    gsap.to(this.material.uniforms.uReflectivity, { value: blobSettings.reflectivity })
    gsap.to(this.material.uniforms.uHue, { value: blobSettings.colour })
    gsap.to(this.material.uniforms.uRed, { value: blobSettings.red })
    gsap.to(this.material.uniforms.uGreen, { value: blobSettings.green })
    gsap.to(this.material.uniforms.uBlue, { value: blobSettings.blue })
    gsap.to(this.material.uniforms.uGradientStartColor.value, { r: blobSettings.gradStart.r / 255, g: blobSettings.gradStart.g / 255, b: blobSettings.gradStart.b / 255 })
    gsap.to(this.material.uniforms.uGradientEndColor.value, { r: blobSettings.gradEnd.r / 255, g: blobSettings.gradEnd.g / 255, b: blobSettings.gradEnd.b / 255 })
    gsap.to(this.material.uniforms.uBrightness, { value: blobSettings.brightness })
    gsap.to(this.material.uniforms.uContrast, { value: blobSettings.contrast })
    gsap.to(this.material.uniforms.uOscilation, { value: blobSettings.oscilation })
    gsap.to(this.material.uniforms.uTextureVisibility, { value: blobSettings.textureVisibility })
    gsap.set(this.material.uniforms.uTexture, { value: textureMaps[blobSettings.texture] })
    gsap.to(".main", { background: `linear-gradient(90deg, rgb(${blobSettings.gradStart.r},${blobSettings.gradStart.g},${blobSettings.gradStart.b}) 0%, rgb(${blobSettings.red * 500},${blobSettings.green * 500},${blobSettings.blue * 500}) 57%, rgb(${blobSettings.gradEnd.r},${blobSettings.gradEnd.g},${blobSettings.gradEnd.b}) 100%)` })
}
}