import Gl from './gl';
import Blob from './gl/Blob';
import gsap from 'gsap';

class App {
  constructor() {
    this.blob = null;
    this.addBlobs();
  }

  addBlobs() {
    this.blob = new Blob();   
    this.blob.position.set(0, 0, 0);
    this.blob.rotation.set(0, 0, 0);

    Gl.scene.add(this.blob);

    gsap.fromTo(this.blob.rotation, {
        y: Math.PI
    },{
        y: -Math.PI,
        duration: 1,
        ease: 'ease-in-out'
    });

    gsap.fromTo(this.blob.position, {
        z: -100
    },{ z: 0, duration: 1, ease: 'ease-in-out' });
  }
}

new App();